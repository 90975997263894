import React , { useState , useEffect }from 'react'

export default function Buttons ({setLogOut , back , theRegresar }) {

    const [regresar,setRegresar] = useState(false) // El estado del boton de regresar

    useEffect(() => {
        if( theRegresar )
        {
            setRegresar(true)
        }
        else{
            setRegresar(false)
        }
    }, [theRegresar])

    function onRegresar() {

        console.log( "onRegresar" )
        back()
       
    }

    function onLogOut() {
        //localStorage.removeItem('token')
        //window.location.href = '/login'
        setLogOut(false)
    }

  return (
    <div>
        <div className='row'>
            <div className='col-6'>
                <button type="button" onClick={onLogOut} className="btn btn-primary mt-3 button-3">LogOut</button>
            </div>

            {
                regresar && (
                    <div className='col-6'>
                        <button type="button" onClick={onRegresar} className="btn btn-primary mt-3 button-3">Regresar</button>
                    </div>
                )
            }

        </div>
    </div>
  )
}

