import React , { useState , useEffect } from 'react'

import { useNavigate  } from "react-router-dom";

import { ApiCallGet } from '../ApiCall/ApiCall';
import CargaPhotos from '../CargaPhotos/CargaPhotos';




export default function Login( ) {

    const [usuario, setUsuario] = useState("");   
    const [password, setPassword] = useState("");

    const [hideLogin,setHideLogin] = useState( false )


    //const navigate = useNavigate();

    //const location = useLocation();


    function setLogOut( val )
    {
        console.log( "setLogOut" , val )
        setHideLogin( val )
    }
    

    function onSubmit(e) {
        e.preventDefault();
        console.log("submit");

        if ( usuario === "" || password === "" ){
            alert("Debe llenar todos los campos")
            return;
        }

        
        

        ApiCallGet(  'login/'+ usuario + '/' + password)
        .then( (response) => {
            console.log( response )

            if ( response.length === 0 || response === 'notAvailable' )
            {   //navigate("/") // aqui habia login
                alert("Usuario o contraseña incorrectos")

                return ;
                
            }

            alert("Bienvenido " + response[0].cName )

            setHideLogin( true )

        } )
        .catch( (error) => {
            console.log( error )

            alert("No se pudo conectar con el servicio")
        })

        



    }


  return (
    <div className='d-flex flex-column  justify-content-center box-1 backemployer-2 centersearch' >

    < div className='d-flex flex-column justify-content-center box-1 backemployer-2 mb-5 form-2 w-100'  id="page1" style={{margin : 'auto'}}>

    <h1>Digital Activaciones</h1>

    {
         !hideLogin    && (

    <>

    <h3>Login</h3>

    <form onSubmit={onSubmit}>

        <div className='row'>

            <div className='col-12'>
                <label className="form-label m-0" htmlFor="usuario">Usuario</label>
                <input type="text" className="form-control" id="usuario" onChange={(e) => setUsuario(e.target.value)} />
            </div>

        </div>

        <div className='row'>

            <div className='col-12'>
                <label className="form-label m-0" htmlFor="password">Password</label>
                <input type="password" className="form-control" id="password" onChange={(e) => setPassword(e.target.value)}/>
            </div>

        </div>

        <div className='col'>

            <button type="submit"  className="btn btn-primary mt-3 button-3"  >Aceptar</button>

        </div>


    </form>

    </>

    )
    }

    { hideLogin && (
    <div style={{margin:'auto'}}>
        <CargaPhotos usuario={usuario} setLogOut={setLogOut}/>
    </div> )
    }

    </div>
    </div>
  
  )
}
