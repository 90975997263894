import React , { useState , useEffect } from 'react'

import { ApiCallGet } from '../ApiCall/ApiCall'
//import ShowFolio from '../ShowFolio/ShowFolio'
import LoadPhoto from './LoadPhoto'
//import DisplayList from './DisplayList'
import DisplayCards from './DisplayCards'
import Buttons from '../Buttons/Buttons'

export default function CargaPhotos( { usuario  , setLogOut }) {
    const [foliocita,setFolioCita] = useState( "")
    const [showData,setShowData] = useState( false )
    const [myData,setMyData] = useState([])
    const [id,setId] = useState( null )
    const [index,setIndex] = useState( null )
    const [viewCards,setTheCards] = useState( true )
    //const [theRegresar,setTheRegeresar] = useState( false )


    function onBack() // Se tecleo el boton de regresar
    {
        console.log( "onBack" )
        setShowData( false )
        setMyData( [] )
        setId( null )
        setIndex( null )
        //setTheRegeresar( false )
    }
 

    useEffect(() => {
        console.log( "ViewCards = " + viewCards  )
    }, [viewCards]  )

    function setTheNumFolio( val )
    {
         console.log( "setTheNumFolio = " , val +  myData[val].selected  + " viewCards = " + viewCards )

         //if ( myData[val].selected  === true  )
         //{ 
            setTheCards( !viewCards )
            setIndex( val )

           
         //}
         
    }

    function onAceptar()
    {
        console.log( "Folio Cita = " + foliocita )


        ApiCallGet( 'citasbydate/' + foliocita +"/" + usuario )
        .then( (response) => {
            console.log( response )

            if ( response === 'notAvailable' ){
                alert( "No se encontraron entregas" )
                return ;
            }
            else{
                //alert( "Se encontraron los folios" )

                setId( "las respuestas = " + response[0].idmsgseg )

                for( var i = 0 ; i < response.length ; i++ )
                {
                    response[i].selected = false
                }

                setMyData( response )
                setShowData( true ) // Mostramos los datos
            }
            }
         )
        .catch( (error) => {
            console.log( error )
        })


        return ;

    }
  return (
    
    <>
        <div className='row'>

            {/* <div className='col-8'>
                <label className="form-label m-0" htmlFor="foliocita">Folio Cita</label>
                <input type="text" className="form-control" id="foliocita" onChange={(e) => setFolioCita(e.target.value)} />
            </div> */}

            <div className='col-8'>
                <label className="form-label m-0" htmlFor="fechacita">Fecha Ruta</label>
                <input type="date" className="form-control" id="fechacita" onChange={(e) => setFolioCita(e.target.value)} />
            </div>


            {/* Esta es una prueba de acceso a la base de datos */}

            <div className='col-4 pt-2'>
                <button type="button"  className="btn btn-primary mt-3 button-3" onClick={onAceptar} >Aceptar</button>
                
            </div>

            {
                showData && viewCards && (
                    <DisplayCards myData={myData}  setNumFolio={setTheNumFolio} back={onBack} />
                )
            }

            {
                showData && (
                    <>
                        {/*<DisplayList myData={myData}  setNumFolio={setTheNumFolio}></DisplayList>*/}

                        
                        {/* <ShowFolio  datos={myData}></ShowFolio> */}
                        <LoadPhoto usuario={usuario} id={id} index={index} myData={myData}></LoadPhoto>
                    </>
                )

            }

            <Buttons setLogOut={setLogOut} back={onBack} theRegresar={showData}></Buttons>

        </div>
    </>
  )
}
