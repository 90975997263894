import React , { useState , useEffect } from 'react'

import './cardstyle.css'

 export default function ShowCard ( { data , setNumFolio , index , setActivado }) { // Data trae solo una tarjeta

  const [letrero,setLetrero] = useState( "Subir Imagenes")

  function setFolioActivado() 
  {
    if ( letrero === "Subir Imagenes" )
    {
      setNumFolio( index )
    }
    else{
      setActivado( index )
    }

  }

  useEffect(()=>{

    console.log( "data.estatus = " + data.estatus )

    if ( data.estatus === null ||  data.estatus.trim() === '' )
    {
      console.log( "vamos a cambiar letrero")
      setLetrero( "Cambiar a Solicitado")
    }

  },[data])

  return (
    <div className='card' >
        <p>Celular : {data.celular}</p>
        <p>Cliente : {data.cliente}</p>
        <p>Fuerza de Venta : {data.fv}</p>
        <p>Estatus : {data.estatus}</p>
        <p>Fotos Subidas : {data.numPhotos}</p>
        <p>Fecha : {data.fecha}</p>
        <p>Folio : {data.foliocita}</p>
        <p>Observaciones : {data.observaciones}</p>

        <div className='row'>

          <div className='col-2'>

          </div>

          <div className='col-4'>
            <button className="btn btn-primary mt-3 button-3" onClick={(val) =>setFolioActivado(index)} >{letrero}</button>
          </div>

          <div className='col-1'>

          </div>

          {
            data.numPhotos > 0 && (
              <div className='col-4'>
                <button className="btn btn-primary mt-3 button-3" >Ver imagenes</button>
              </div>
            )
          }


        </div>

        
        

    </div>
  )
}

