import React  , { useState }from 'react'

import ShowCard from '../ShowCards/ShowCard'

import { ApiCallGet } from '../ApiCall/ApiCall'

export default function DisplayCards( {myData , setNumFolio  , back }) {

  const [data,setData] = useState(myData)

  function setActivado( index )
  {

    console.log( "setActivado = " + index )

    //setData( temp )

    var url = "setsolicitado/?id=" + data[index].idmsgseg ;

    console.log( "url para setsolictado = " + url )

    ApiCallGet( 'setsolicitado/' + data[index].idmsgseg )
    .then((response) => {

        console.log( "set solcicitado = " + response )

        if ( response === "ok" ) // Debe ser en minusculas
        {
            back() ; // Llamamos a limpiar regresarnos a la opcion anterior
            alert( "Se ha marcado como solicitado")
        }

    })
    .catch((error) => {
        // handle error
        console.log( "error = " + error )
        alert( "Error al marcar como solicitado")
    })

    


  }

  function getList( index )
  {
    return(
      <>
        <td>
          <ShowCard data={data[index]} setNumFolio={setNumFolio} setActivado={setActivado} index={index} />
        </td>
      </>
    )
  }

  return (
    <div>
        <div className='row mt-5'>
        <div className='col-12'>
        <table className="table table-striped w-75" style={{margin:'auto'}} >
           
                <thead >
                    <tr >
                        <th scope="col">Ruta</th>
                    </tr>
                </thead>
                <tbody>

                {
                    data.map( (item, index) => {
                        return  (<tr>
                            {getList(index)}
                        </tr> );
                    })
                            
                        
                    
                }
            </tbody>
        </table>
      </div>
      </div>
    </div>
  )
}
