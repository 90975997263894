import React from 'react'

export default function GetUrl() {

    //return "https://boxy.currenmexico.com/legalback/" // Produccion

    //return "http://localhost:3050/digactiva/"

    return "https://mxgsesoftware.com/digactiva/" // desarollo
    
}
