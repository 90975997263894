
import axios from 'axios'
import GetUrl from './GetUrl';

function ApiCallGet( url  ) // Se manda data si es un p
{
    return new Promise(( resolve,reject) => {

        console.log( "Por el ApiCall get " + GetUrl() + url ) ;


        axios.get( GetUrl() + url , {
            headers: {
              // Overwrite Axios's automatically set Content-Type
              'Access-Control-Allow-Origin': '*',
              'Content-Type': 'application/json'
            }
          })
          .then((response) => {
            //console.log( "LLego la respuesta " + JSON.stringify( response.data ) )
            resolve(response.data) ;
          })
          .catch((err) =>{
            console.log( err) ;
            reject( err ) ;
          })
          


    })


}

function ApiCallPost( url2 , data )
{


    var url = filterUrl( url2 ) ;


    console.log( "ApiCallPost url = " + url )

    //console.log( "Llamando a ApiCallPost") ;

    return new Promise(( resolve,reject) => {

        console.log( "Por el ApiCall " + JSON.stringify( data  ).substring( 0 , 30 ) ) ; // substring





        axios.post( GetUrl() + url ,  JSON.stringify( data  )    , {
            headers: {
              // Overwrite Axios's automatically set Content-Type
              'Access-Control-Allow-Origin': '*',
              'Content-Type': 'application/json'
            }
          })
          .then((response) => {
            console.log( "LLego la respuesta " + response.data )
            resolve(response.data) ;
          })
          .catch((err) =>{
            console.log( err) ;
            reject(err) ;
          })
          


    })



}


function ApiCallPostMultipart( url , data )
{
  console.log( "ApiCallPost url *** = " + url )

  //console.log( "Llamando a ApiCallPost") ;

  return new Promise(( resolve,reject) => {

      console.log( "Por el ApiCall *** " , data   ) ;





      axios.post( GetUrl() + url ,   data      , {
          headers: {
            // Overwrite Axios's automatically set Content-Type
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((response) => {
          console.log( "LLego la respuesta " + response.data )
          resolve(response.data) ;
        })
        .catch((err) =>{
          console.log( "regreando por error")
          console.log( err) ;
          reject( err ) ;
        })
        


  })

}

function filterUrl( u )
{
  var url = u.replace( "c_" , "") ; // Le quitamos el prefijo

  url = url.replace( "C_" , "" ) ;

  return url ;
}


//module.exports = { ApiCallGet , ApiCallPost } ;

export { ApiCallGet , ApiCallPost , ApiCallPostMultipart }