
import { BrowserRouter, Routes, Route  } from "react-router-dom";
import './App.css';
import Login from "./Components/Login/Login";
//import CargaPhotos from "./Components/CargaPhotos/CargaPhotos";

function App() {
  return (
    // <BrowserRouter> 
      <div className="w-100" >
        <Login/>
      </div>

    /*  <Routes>

          <Route path="/cargafotos" element={<CargaPhotos/>} />

          <Route path="/login" element={<Login/>} />
 
      </Routes> 

    </BrowserRouter> */
  );
}

export default App;
